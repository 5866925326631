<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="onSubmit"
  >
    <v-card
      height="100%"
      flat
      :loading="loading"
    >
      <v-card-title>
        <slot name="form-title">
          <span class="font-weight-semibold text-base text--primary">Add a role</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="$emit('update:is-form-active',false)"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </slot>
      </v-card-title>

      <v-card-text
        class="overflow-y-hidden"
        style="max-height: 600px"
      >
        <v-row class="pt-5">
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="item.name"
              :disabled="item.id && $can('edit_rolename','Role')"
              outlined
              dense
              :rules="[validators.required]"
              :error-messages="errorMessages.name"
              :label="$t('Code')"
              :placeholder="$t('Code')"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <translatable-text-field
              v-model="item.display_name"
              outlined
              dense
              :label="$t('Name')"
              :placeholder="$t('Name')"
              hide-details="auto" ></translatable-text-field>
          </v-col>
          <v-col
            cols="12"
          >
            <v-text-field
              v-model="search"
              :placeholder="$t('Search...')"
              item-text="display_name"
              item-value="name"
              outlined
              dense
              hide-details="auto"
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            style="height: 350px; max-height: 350px; overflow-y: auto"
          >
            <!--                <v-list dense two-line style="height: 350px; max-height: 350px; overflow-y: auto">

                    <template v-for="(perm, index) in permissions.filter((ele) => ele.display_name && ele.display_name.toLowerCase().includes(search.toLowerCase()) || ele.name && ele.name.toLowerCase().includes(search.toLowerCase()))">
                      <v-list-item dense :key="perm.name" style="min-height: auto !important;">
                        <v-list-item-content>
                          <v-list-item-title>{{ perm.display_name}}</v-list-item-title>
&lt;!&ndash;                          <v-list-item-subtitle>{{ perm.name}}</v-list-item-subtitle>&ndash;&gt;
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-checkbox v-model="item.permissions" :value="perm.name"></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider v-i="index < permissions.filter((ele) => ele.display_name && ele.display_name.toLowerCase().includes(search.toLowerCase()) || ele.name && ele.name.toLowerCase().includes(search.toLowerCase())).length-1" :key="index" />
                    </template>
                </v-list>-->

            <v-expansion-panels>
              <v-expansion-panel
                v-for="tab in tabs.filter((ele) => ele.label && ele.label.toLowerCase().includes(search.toLowerCase()) || ele.tab && ele.tab.toLowerCase().includes(search.toLowerCase()))"
                :key="tab.tab"
              >
                <v-expansion-panel-header>
                  {{ tab.label }} ({{ permissions.filter(ele => ele.tab === tab.tab && ele.type === 'view' ).length }})
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list dense>
                    <v-list-item dense>
                      <v-list-item-action>
                        <v-checkbox
                          v-model="tab.all"
                          @change="selectAllParent(tab)"
                        />
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          Select all permissions
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <template v-for="perm in permissions.filter(ele => ele.tab === tab.tab && ele.type === 'view' )">
                      <v-list-group
                        ref="refVListGroup"
                        dense
                        class="vertical-nav-menu-group"
                      >
                        <template #activator>
                          <v-list-item-title>{{ $t(perm.display_name) }}</v-list-item-title>
                        </template>

                        <v-list-item dense>
                          <v-list-item-action>
                            <v-checkbox
                              v-model="perm.all"
                              @change="selectAll(tab,perm)"
                            />
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ $t("Select all") }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider />
                        <template v-for="(child,ind) in permissions.filter(ele => ele.tab === tab.tab && ele.group === perm.group)">
                          <v-list-item

                            dense
                          >
                            <v-list-item-action>
                              <v-checkbox
                                v-model="item.permissions"
                                :value="child.name"
                                @change="selectItem(tab,child, perm)"
                              ></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ getItem(types, 'value', child.type).text || 'Voir' }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider v-if="ind < permissions.filter(ele => ele.tab === tab.tab && ele.group === perm.group).length" />
                        </template>
                      </v-list-group>
                    </template>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <slot name="form-actions">
          <slot name="form-cancel"></slot>
          <v-switch class="ml-2" label="Shop role" v-model="item.shop" />
          <v-spacer />
          <v-btn
            :loading="loading"
            color="primary"
            class="me-3"
            type="submit"
          >
            {{ $t("Save") }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mdiClose, mdiPencilOutline, mdiPencilOffOutline } from '@mdi/js'
import {computed, onUnmounted, ref, watch} from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import controller from './Controller'
import permissionController from '../permission/Controller'
import userStoreModule from "@/views/apps/user/userStoreModule";
import TranslatableTextField from "@/components/TranslatableTextField";

export default {
  components: {TranslatableTextField},
  model: {
    prop: 'object',
    event: 'update:is-form-active',
  },
  props: {
    object: {
      type: Object,
      default: () => {},
    },
    isFormActive: {
      type: Boolean,
      required: true,
    },
    withPassword: {
      type: Boolean,
      default: true,
    },
    roleOptions: {
      type: Array,
      default: () => [],
    },
    planOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    const {
      form,
      isSnackbarBottomVisible,
      snackBarMessage,
    } = controller()
    const {
      types,
      tabs,
    } = permissionController()
    const permissions = ref([])
    const errorMessages = ref({ })
    const valid = ref(false)
    const loading = ref(true)
    const isLoadingPermissions = ref(false)
    const validate = () => {
      form.value.validate()
    }

    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })

    // const item = ref(JSON.parse(JSON.stringify(blankItem)))
    const resetItem = () => {
      // item.value = {}
      // resetForm()

      // emit('update:object', item.value)
    }
    const selectItem = (tab, perm, main) => {
      if (perm.type === 'edit' && item.value.permissions.indexOf(perm.name) > -1) {
        const viewPermissions = permissions.value.filter(ele => ele.tab === tab.tab && ele.group === perm.group && ele.type === 'view')
        if (viewPermissions && viewPermissions.length) {
          const viewPermission = viewPermissions[0]
          if (item.value.permissions.indexOf(viewPermission.name) < 0) {
            item.value.permissions.push(viewPermission.name)
          }
        }
      }

      const perms = permissions.value.filter(ele => ele.tab === tab.tab && ele.group === perm.group).map(ele => ele.name)
      let all = true
      for (let i = 0; i < perms.length; i++) {
        if (item.value.permissions.indexOf(perms[i]) < 0) {
          console.log(perms[i])
          all = false
          break
        }
      }

      main.all = all

      const tabPerms = permissions.value.filter(ele => ele.tab === tab.tab).map(ele => ele.name)
      let allTab = true
      for (let i = 0; i < perms.length; i++) {
        if (item.value.permissions.indexOf(perms[i]) < 0) {
          allTab = false
          break
        }
      }
      tab.all = allTab
    }
    const selectAll = (tab, perm) => {
      const perms = permissions.value.filter(ele => ele.tab === tab.tab && ele.group === perm.group).map(ele => ele.name)

      if (perm.all) {
        for (let i = 0; i < perms.length; i++) {
          if (item.value.permissions.indexOf(perms[i]) < 0) {
            item.value.permissions.push(perms[i])
          }
        }
      } else {
        for (let i = 0; i < perms.length; i++) {
          const ind = item.value.permissions.indexOf(perms[i])
          if (ind > -1) {
            item.value.permissions.splice(ind, 1)

            // console.log(item.value.permissions)
          }
        }
      }
    }

    const selectAllParent = tab => {
      const perms = permissions.value.filter(ele => ele.tab === tab.tab).map(ele => ele.name)

      if (tab.all) {
        for (let i = 0; i < perms.length; i++) {
          if (item.value.permissions.indexOf(perms[i]) < 0) {
            item.value.permissions.push(perms[i])
          }
          const index = permissions.value.findIndex(ele => ele.name === perms[i])
          if (index > -1) {
            const p = permissions.value[index]
            p.all = true
            permissions.value[index] = p
          }
        }
      } else {
        for (let i = 0; i < perms.length; i++) {
          const ind = item.value.permissions.indexOf(perms[i])
          if (ind > -1) {
            item.value.permissions.splice(ind, 1)
            const index = permissions.value.findIndex(ele => ele.name === perms[i])
            if (index > -1) {
              const p = permissions.value[index]
              p.all = false
              permissions.value[index] = p
            }

            // console.log(item.value.permissions)
          }
        }
      }
    }

    const onSubmit = () => {
      if (valid.value) {
        loading.value = true
        store.dispatch('role/addItem', item.value)
          .then(response => {
            loading.value = false
            if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
              if (response.data.errors) {
                // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
                errorMessages.value = { ...response.data.errors }
              } else {
                isSnackbarBottomVisible.value = true
                snackBarMessage.value = response.data.message

                // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
              }

              return false
            }
            resetItem()

            // emit('update:is-form-active', false)

            emit('refetch-data')
          }).catch(error => {
            loading.value = false
            console.log('error', error)
            if (error.data.errors) {
            // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              errorMessages.value = { ...error.data.errors }
            }
          })
      } else {
        validate()
      }
    }
    const search = ref('')

    store.dispatch('role/searchPermissions', { term: '', rowsPerPage: 1000 }).then(response => {
      loading.value = false

      permissions.value = response
    })

    // onMounted(searchParticular)
    // eslint-disable-next-line no-unused-vars
    /* watch(() => props.object, (newValue, prevValue) => {
      if (!newValue || !newValue.id) {
        resetItem()
      } else {
        item.value = JSON.parse(JSON.stringify(newValue))
      }
    }) */
    // eslint-disable-next-line no-unused-vars
    /* watch(() => item, (newValue, prevValue) => {
      console.log('item change', newValue.value)
      emit('input', newValue.value)
    }, { deep: true }) */

    return {
      types,
      tabs,
      selectAll,
      selectItem,
      selectAllParent,
      resetItem,
      form,
      isLoadingPermissions,
      isSnackbarBottomVisible,
      snackBarMessage,
      errorMessages,
      onSubmit,
      countries,
      item,
      valid,
      permissions,
      search,
      loading,
      validate,

      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
        mdiPencilOffOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>
