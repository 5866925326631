import axios from '@axios'

export default {
  namespaced: true,
  state: {
    items: [],
    roles: [],
  },
  getters: {},
  mutations: {
    USERS(state, value) {
      console.log('users', value)
      state.items = value
    },
  },
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/users', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchItems(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/roles', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchLocations(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/locations', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/users/web/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/users/web',
          data: userData,
          // eslint-disable-next-line no-unused-vars
          validateStatus: status => true,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUserCredit(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/users/credit',
          data: userData,
          // eslint-disable-next-line no-unused-vars
          validateStatus: status => true,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addItem(ctx, itemData) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/roles',
          data: itemData,
          // eslint-disable-next-line no-unused-vars
          validateStatus: status => true,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateAvatar(ctx, imgUrl) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/users/store_avatar',
          data: { avatar: imgUrl },
          // eslint-disable-next-line no-unused-vars
          validateStatus: status => true,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changePassword(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/users/change_password',
          data: userData,
          // eslint-disable-next-line no-unused-vars
          validateStatus: status => true,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteItem(ctx, uri) {
      return new Promise((resolve, reject) => {
        axios
          .delete(uri)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    searchUsers(ctx, options) {
      return new Promise((resolve, reject) => {
        axios
          .get('/users', { params: options })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    searchPermissions(ctx, options) {
      return new Promise((resolve, reject) => {
        axios
          .get('/permissions', { params: options })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    fetchRoles({ state }, options) {
      axios
        .get('/roles', { params: options })
        .then(response => {
          state.roles = response.data.data
        })
        .catch(error => console.log(error))
    },
  },
}
